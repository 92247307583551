import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { download_customer_balances } from "../../api/otogas";
import { formatCurrency } from "../../api/util";
// import { upload_sales_data } from "../../api/otogas";
import FileUpload from "../../components/util/uploader";

const ListCustomerBalances = () => {
    const customersContext = useContext(CustomersContext);
    const {
        customer_balances,
        notification,
        clear_notification,
        listCustomerBalances,
        topUpCustomerBalance,
        uploadSalesData,
    } = customersContext;

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // You can adjust the number of items per page as needed
    const [showModal, setShowModal] = useState(false);
    const [selectedCustomerBalance, setSelectedCustomerBalance] =
        useState(null);
    const [topUpAmount, setTopUpAmount] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [downloading, setDownloading] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        listCustomerBalances();
    }, [listCustomerBalances]);

    useEffect(() => {
        if (notification) {
            console.log(notification);
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    const handleDownloadBalances = async () => {
        try {
            setDownloading(true);
            await download_customer_balances();
            setDownloading(false);
        } catch (error) {
            console.error("Error downloading customer balances", error);
            setDownloading(false);
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    const handleTopUp = (customer_balance) => {
        setSelectedCustomerBalance(customer_balance);
        setShowModal(true); // Show the modal
    };

    const handleTopUpSubmit = async () => {
        if (topUpAmount && parseFloat(topUpAmount) > 0 && paymentReference) {
            try {
                console.log(selectedCustomerBalance);
                await topUpCustomerBalance({
                    vehicleCode: selectedCustomerBalance.vehicleCode,
                    amount: parseFloat(topUpAmount),
                    paymentReference,
                });
                setShowModal(false); // Hide the modal after successful top-up
            } catch (error) {
                console.error("Error topping up balance", error);
            }
        }
    };

    // Filter balances based on search term
    const filteredBalances = customer_balances.filter((balance) => {
        const matchesSearch = balance.customerName
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        return matchesSearch;
    });

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBalances = filteredBalances.slice(
        indexOfFirstItem,
        indexOfLastItem,
    );
    const totalPages = Math.ceil(filteredBalances.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                <h2>Wallet Balances</h2>

                <div className="row mb-3">
                    <div className="col-md-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by customer name"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="col-md-4 d-flex justify-content-start">
                        <button
                            className="btn btn-success m-1"
                            onClick={handleDownloadBalances}
                            disabled={downloading}
                        >
                            {downloading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Downloading...
                                </>
                            ) : (
                                "Download Balances"
                            )}
                        </button>

                        <FileUpload
                            uploadFunction={uploadSalesData}
                            name="Upload balances"
                            allowed_file_types={[".xlsx", ".xls"]}
                        />
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Balance Amount</th>
                            <th>Registration Number</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentBalances.map((balance) => (
                            <tr key={balance.customerId}>
                                <td>{balance.customerName}</td>
                                <td>{formatCurrency(balance.balance)}</td>
                                <td>{balance.registrationNumber}</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-custom-actions dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Actions
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                        handleTopUp(balance)
                                                    }
                                                >
                                                    Top-up Balance
                                                </button>
                                            </li>

                                            <li>
                                                {/* <button className="dropdown-item" onClick={() => handleTopUp(balance)}>
                          View History
                        </button> */}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination controls */}
                <div className="pagination-controls">
                    <button
                        className="btn btn-primary"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="mx-3">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>

                {/* Modal for Top-up */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Top-up Balance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Top-up Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={topUpAmount}
                                    onChange={(e) =>
                                        setTopUpAmount(e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Payment Reference</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter payment reference"
                                    value={paymentReference}
                                    onChange={(e) =>
                                        setPaymentReference(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleTopUpSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Main>
        </>
    );
};

export default ListCustomerBalances;
