import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    Button,
    Modal,
    Form,
    Alert,
    InputGroup,
    FormControl,
    Row,
    Col,
    ListGroup,
    Spinner,
} from "react-bootstrap";

import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";

import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import StationsContext from "../../context/otogas/stations/stationsContext";
import { set } from "date-fns";

const ReportRecipients = () => {
    const stationsContext = useContext(StationsContext);
    const {
        listReports,
        report_list,
        addReportRecipient,
        removeReportRecipient,
        listReportRecipients,
        report_recipients,
        notification,
        clear_notification,
    } = stationsContext; // Default to empty array

    const [selectedReport, setSelectedReport] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [newRecipient, setNewRecipient] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [recipientToRemove, setRecipientToRemove] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [loadingReports, setLoadingReports] = useState(true); // Add loading state for reports
    const [loadingRecipients, setLoadingRecipients] = useState(false); // Add loading state for recipients

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    // Fetch reports when component mounts
    useEffect(() => {
        setLoadingReports(true); // Set loading true while fetching reports
        listReports();
        setLoadingReports(false); // Set loading false when done fetching
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    // Fetch recipients when a report is selected
    useEffect(() => {
        if (selectedReport) {
            const fetchRecipients = async () => {
                try {
                    setLoadingRecipients(true); // Set loading true while fetching recipients
                    const response = await listReportRecipients(selectedReport); // Await the response
                    setRecipients(response); // Update the recipients list
                } catch (error) {
                    console.error("Error fetching recipients:", error);
                } finally {
                    setLoadingRecipients(false); // Set loading false when done fetching
                }
            };

            fetchRecipients();
        }
    }, [selectedReport]);

    const handleConfirmAlert = () => {
        setShowAlert(false);
        clear_notification();
        listReports();
        if (selectedReport) {
            listReportRecipients(selectedReport);
        }
    };

    const handleSelectReport = (reportKey) => {
        setSelectedReport(reportKey);
    };

    const handleAddRecipient = () => {
        console.log(newRecipient);
        console.log(report_recipients); // Should be an array here

        if (newRecipient) {
            addReportRecipient(selectedReport, newRecipient); // Add recipient using context
        }
    };

    const handleRemoveRecipient = () => {
        removeReportRecipient(selectedReport, recipientToRemove); // Remove recipient using context
        setRecipients(report_recipients.filter((r) => r !== recipientToRemove));
        setShowConfirmModal(false);
        // setAlert({ show: true, message: 'Recipient removed successfully!', variant: 'success' });
    };

    return (
        <div>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirmAlert}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                <h3>Select a Report</h3>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search reports..."
                        aria-label="Search reports"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>

                {loadingReports ? (
                    <Spinner animation="border" /> // Show spinner while loading reports
                ) : (
                    <Row>
                        {report_list?.length > 0 ? (
                            report_list.map((report) => (
                                <Col
                                    key={report.id}
                                    md={4}
                                    sm={6}
                                    lg={3}
                                    className="mb-3"
                                >
                                    <Card
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            handleSelectReport(report.id)
                                        }
                                        className={`shadow-sm ${selectedReport === report.id ? "border-primary" : ""}`}
                                    >
                                        <Card.Body>
                                            <Card.Title>
                                                {report.reportName}
                                            </Card.Title>{" "}
                                            {/* Corrected field name */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <p>No reports available</p>
                        )}
                    </Row>
                )}

                {selectedReport && (
                    <div className="mt-4">
                        <h4>
                            Recipients for{" "}
                            {
                                report_list.find((r) => r.id === selectedReport)
                                    ?.name
                            }
                        </h4>

                        {loadingRecipients ? (
                            <Spinner animation="border" /> // Show spinner while loading recipients
                        ) : (
                            <ListGroup>
                                {report_recipients.map((recipient, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        {recipient}
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                                setRecipientToRemove(recipient);
                                                setShowConfirmModal(true);
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}

                        <Button
                            variant="success"
                            className="mt-3"
                            onClick={() => setShowModal(true)}
                        >
                            Add Recipient
                        </Button>

                        {/* Add Recipient Modal */}
                        <Modal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Recipient</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={newRecipient}
                                        onChange={(e) =>
                                            setNewRecipient(e.target.value)
                                        }
                                        placeholder="Enter recipient email"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        handleAddRecipient();
                                        setShowModal(false);
                                    }}
                                >
                                    Add Recipient
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Confirm Remove Modal */}
                        <Modal
                            show={showConfirmModal}
                            onHide={() => setShowConfirmModal(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Removal</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to remove{" "}
                                {recipientToRemove}?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowConfirmModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={handleRemoveRecipient}
                                >
                                    Remove
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}
            </Main>
        </div>
    );
};

export default ReportRecipients;
