import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
    searchVehicle,
    searchEmployee,
    validatePaymentReference,
} from "../../api/otogas";
import CustomersContext from "../../context/otogas/customers/customersContext";
import debounce from "lodash.debounce";

const VarianceClearanceModal = ({ show, handleClose, selectedVariance }) => {
    const [paymentType, setPaymentType] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [amountPayable, setAmountPayable] = useState(0);
    const [paymentReference, setPaymentReference] = useState("");
    const [comment, setComment] = useState("");
    const [vehicleDetails, setVehicleDetails] = useState(null);
    const [price, setPrice] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPaymentReference, setShowPaymentReference] = useState(false);
    const [step, setStep] = useState(1);
    const [paymentReferenceValid, setPaymentReferenceValid] = useState(null);

    // Error states
    const [vehicleError, setVehicleError] = useState("");
    const [quantityError, setQuantityError] = useState("");
    const [paymentReferenceError, setPaymentReferenceError] = useState("");

    const {
        vehicles,
        fetchVehicles,
        payment_types: paymentTypes,
        listPaymentTypes,
        addSale,
    } = useContext(CustomersContext);

    useEffect(() => {
        listPaymentTypes();
        resetForm();
    }, []);

    // Automatically fetch the price based on user or vehicle for Wallet, Mpesa, Insurance, and other types
    useEffect(() => {
        if (
            (paymentType === 5 ||
                paymentType === 8 ||
                paymentType === 3 ||
                paymentType === 4) &&
            selectedVariance?.userCode
        ) {
            fetchPriceForUser(selectedVariance.userCode);
        } else if (paymentType === 6 && selectedVariance?.userCode) {
            fetchPriceForUser(selectedVariance.userCode);
        } else if (
            (paymentType === 0 || paymentType === 1 || paymentType === 9) &&
            vehicleDetails?.vehicleCode
        ) {
            fetchPriceForVehicle(vehicleDetails.vehicleRegistrationNumber);
        } else if (vehicleDetails?.vehicleCode) {
            fetchPriceForVehicle(vehicleDetails.vehicleCode);
        }
    }, [paymentType, selectedVariance?.userCode, vehicleDetails?.vehicleCode]);

    // Function to fetch price for user (employee)
    const fetchPriceForUser = async (userCode) => {
        setIsLoading(true);
        try {
            const priceData = await searchEmployee(userCode);
            if (priceData) {
                setPrice(priceData.price);
                setStep(2);
            } else {
                alert("Price not found or error occurred.");
            }
        } catch (error) {
            console.error("Error fetching price:", error);
        }
        setIsLoading(false);
    };

    const validatepayment = async () => {
        setPaymentReferenceError("");
        setPaymentReferenceValid(null);
        setIsLoading(true);
        try {
            const response = await validatePaymentReference(
                selectedVariance.dispenserCode,
                paymentReference,
            );
            if (response && response.responseCode === 1) {
                if (
                    parseFloat(amountPayable) <=
                    response.responseObject.balanceUsage
                ) {
                    setPaymentReferenceValid(response.responseMessage);
                } else {
                    setPaymentReferenceValid("Insufficient balance");
                }
            } else {
                setPaymentReferenceValid(response.responseMessage);
            }
        } catch (error) {
            setPaymentReferenceValid("Error validating payment.");
        } finally {
            setIsLoading(false);
        }
    };

    // Function to fetch price and wallet balance for vehicle
    const fetchPriceForVehicle = async (vehicleCode) => {
        setIsLoading(true);
        try {
            const vehicleData = await searchVehicle(
                selectedVariance?.stationCode,
                vehicleDetails.vehicleRegistrationNumber,
            );
            if (vehicleData?.responseObject?.price) {
                setPrice(vehicleData.responseObject.price);
                setWalletBalance(
                    vehicleData.responseObject.walletBalance || null,
                );
                setStep(2);
            } else {
                setVehicleError("Price not found or error occurred.");
            }
        } catch (error) {
            setVehicleError("Error fetching price.");
            console.error("Error fetching price:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (price && quantity) {
            const total = price * quantity;
            setAmountPayable(total.toFixed(2));

            if (paymentType === 1 && walletBalance !== null) {
                if (total > walletBalance) {
                    setQuantityError("Entered quantity exceeds wallet balance");
                    setQuantity(0);
                    setAmountPayable(0);
                    setShowPaymentReference(false);
                } else {
                    setQuantityError("");
                }
            } else {
                setQuantityError("");
            }

            if (paymentType === 0 || paymentType === 5) {
                setShowPaymentReference(true);
            } else if (paymentType === 6) {
                setShowPaymentReference(true);
            } else {
                setStep(2);
                setShowPaymentReference(false);
            }
        } else {
            setAmountPayable(0);
            setQuantityError("");
            setShowPaymentReference(false);
        }
    }, [quantity, price, walletBalance, paymentType]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!vehicleDetails?.vehicleCode && paymentType === 0) {
            setVehicleError("Vehicle registration is required.");
            hasErrors = true;
        } else {
            setVehicleError("");
        }

        if (quantity <= 0) {
            setQuantityError("Quantity must be greater than 0.");
            hasErrors = true;
        } else {
            setQuantityError("");
        }

        if (
            showPaymentReference &&
            paymentReference.trim() === "" &&
            paymentType !== 6
        ) {
            setPaymentReferenceError("Payment reference is required.");
            hasErrors = true;
        } else {
            setPaymentReferenceError("");
        }

        if (hasErrors) return;

        const payload = {
            vehicleCode:
                paymentType === 0 ||
                paymentType === 1 ||
                paymentType === 2 ||
                paymentType === 4
                    ? vehicleDetails?.vehicleCode
                    : selectedVariance?.userCode,
            paymentTypeCode: paymentType,
            nozzleCode: selectedVariance?.nozzleCode,
            shiftNumber: selectedVariance?.shiftNumber,
            quantity: parseFloat(quantity),
            dispenserCode: selectedVariance?.dispenserCode,
            comment,
            paymentDetails: [
                {
                    transactionReference: paymentReference,
                    transactionAmount: amountPayable,
                },
            ],
        };

        try {
            // Submit the form data via the API
            const response = await addSale(payload);

            // Check if the response contains errors
            if (response && response.error) {
                setPaymentReferenceError(
                    "Error submitting form: " + response.error.message,
                );
            } else {
                resetForm();
                handleClose();
            }
        } catch (error) {
            // Handle any unexpected errors during the API call

            setPaymentReferenceError(
                "An unexpected error occurred. Please try again.",
            );
        }
        // addSale(payload);
    };

    const resetForm = () => {
        setStep(1);
        setVehicleDetails(null);
        setQuantity(0);
        setAmountPayable(0);
        setPaymentReference("");
        setComment("");
        setPrice(0);
        setWalletBalance(null);
        setShowPaymentReference(false);
        setPaymentReferenceValid(null);
    };

    const [regNo, setRegNo] = useState("");
    const [vehicleOptions, setVehicleOptions] = useState([]);

    const debouncedFetchVehicles = debounce((regNo) => {
        fetchVehicles(1, 10, "", regNo, "", "", true);
    }, 500);

    useEffect(() => {
        if (vehicles) {
            const options = vehicles.map((vehicle) => ({
                value: vehicle.vehicleCode,
                label: vehicle.vehicleRegistrationNumber,
            }));
            setVehicleOptions(options);
        }
    }, [vehicles]);

    const handleChange = (selectedOption) => {
        setVehicleDetails({
            vehicleCode: selectedOption.value,
            vehicleRegistrationNumber: selectedOption.label,
        });
    };

    const handleInputChange = (inputValue) => {
        setRegNo(inputValue);
        debouncedFetchVehicles(inputValue);
    };

    const isSubmitDisabled =
        isLoading ||
        !paymentType ||
        !quantity ||
        (showPaymentReference && !paymentReference) ||
        vehicleError ||
        quantityError ||
        paymentReferenceError;

    return (
        <Modal
            show={show}
            onHide={() => {
                resetForm();
                handleClose();
            }}
            backdrop="static" // Prevents closing when clicking outside
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Variance Clearance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="paymentType">
                        <Form.Label>Select Payment Type</Form.Label>
                        <Select
                            options={paymentTypes.map((type) => ({
                                value: type.paymentTypeId,
                                label: type.paymentTypeName.replace(/_/g, " "),
                            }))}
                            onChange={(selectedOption) => {
                                resetForm();
                                setPaymentType(selectedOption.value);
                                setStep(1);
                            }}
                            placeholder="Select Payment Type"
                        />
                    </Form.Group>

                    {step >= 1 && paymentType === 0 && (
                        <>
                            <Form.Group controlId="vehicleRegistration">
                                <Form.Label>Vehicle Registration</Form.Label>
                                <Select
                                    options={vehicleOptions}
                                    onChange={handleChange}
                                    onInputChange={handleInputChange}
                                    placeholder="Select Vehicle"
                                    value={vehicleOptions.find(
                                        (option) =>
                                            option.value ===
                                            vehicleDetails?.vehicleCode,
                                    )}
                                    isClearable
                                />
                                {vehicleError && (
                                    <Form.Text className="text-danger">
                                        {vehicleError}
                                    </Form.Text>
                                )}
                                {isLoading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Form.Group>
                        </>
                    )}

                    {step >= 1 && paymentType === 2 && (
                        <>
                            <Form.Group controlId="vehicleRegistration">
                                <Form.Label>Vehicle Registration</Form.Label>
                                <Select
                                    options={vehicleOptions}
                                    onChange={handleChange}
                                    onInputChange={handleInputChange}
                                    placeholder="Select Vehicle"
                                    value={vehicleOptions.find(
                                        (option) =>
                                            option.value ===
                                            vehicleDetails?.vehicleCode,
                                    )}
                                    isClearable
                                />
                                {vehicleError && (
                                    <Form.Text className="text-danger">
                                        {vehicleError}
                                    </Form.Text>
                                )}
                                {isLoading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Form.Group>
                        </>
                    )}

                    {step >= 1 &&
                        (paymentType === 3 ||
                            paymentType === 4 ||
                            paymentType === 5 ||
                            paymentType === 6 ||
                            paymentType === 8) &&
                        selectedVariance && (
                            <>
                                <Form.Group controlId="userDetails">
                                    <Form.Label>Employee Details</Form.Label>
                                    <p>
                                        <strong>Name:</strong>{" "}
                                        {selectedVariance?.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>User Code:</strong>{" "}
                                        {selectedVariance?.userCode || "N/A"}
                                    </p>
                                    {isLoading && (
                                        <Spinner animation="border" size="sm" />
                                    )}
                                </Form.Group>
                            </>
                        )}

                    {step >= 1 && paymentType === 1 && (
                        <>
                            <Form.Group controlId="walletDetails">
                                <Form.Group controlId="vehicleRegistration">
                                    <Form.Label>
                                        Vehicle Registration
                                    </Form.Label>
                                    <Select
                                        options={vehicleOptions}
                                        onChange={handleChange}
                                        onInputChange={handleInputChange}
                                        placeholder="Select Vehicle"
                                        value={vehicleOptions.find(
                                            (option) =>
                                                option.value ===
                                                vehicleDetails?.vehicleCode,
                                        )}
                                        isClearable
                                    />
                                    {vehicleError && (
                                        <Form.Text className="text-danger">
                                            {vehicleError}
                                        </Form.Text>
                                    )}
                                    {isLoading && (
                                        <Spinner animation="border" size="sm" />
                                    )}
                                </Form.Group>
                                <Form.Label>Vehicle Wallet Details</Form.Label>
                                <p>
                                    <strong>Price per unit:</strong> {price}
                                </p>
                                <p>
                                    <strong>Wallet Balance:</strong>{" "}
                                    {walletBalance}
                                </p>
                            </Form.Group>
                        </>
                    )}

                    {step >= 2 && (
                        <>
                            <Form.Group controlId="details">
                                <Form.Label>Details</Form.Label>
                                <p>
                                    <strong>Price per unit:</strong> {price}
                                </p>
                            </Form.Group>

                            <Form.Group controlId="quantity">
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => {
                                        const value = parseFloat(
                                            e.target.value,
                                        );
                                        if (value <= 0) {
                                            setQuantityError(
                                                "Quantity must be greater than 0",
                                            );
                                            setQuantity(0);
                                            setAmountPayable(0);
                                        } else if (
                                            paymentType !== 3 &&
                                            value < 0
                                        ) {
                                            setQuantityError(
                                                "Quantity must be greater than or equal to 0",
                                            );
                                            setQuantity(0);
                                            setAmountPayable(0);
                                        } else {
                                            setQuantityError("");
                                            setQuantity(value);
                                            setAmountPayable(value * price);
                                        }
                                    }}
                                    required
                                />
                                {quantityError && (
                                    <Form.Text className="text-danger">
                                        {quantityError}
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group controlId="totalAmount">
                                <Form.Label>Total Payable Amount</Form.Label>
                                <p>
                                    <strong>
                                        {amountPayable > 0
                                            ? amountPayable
                                            : "Enter quantity to calculate"}
                                    </strong>
                                </p>
                            </Form.Group>
                        </>
                    )}

                    {showPaymentReference && (
                        <>
                            <Form.Group controlId="paymentReference">
                                <Form.Label>Payment Reference</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={paymentReference}
                                    onChange={(e) => {
                                        setPaymentReference(e.target.value);
                                        setPaymentReferenceError("");
                                        setPaymentReferenceValid(null);
                                    }}
                                    required={
                                        paymentType === 0 || paymentType === 5
                                    }
                                />
                                {paymentReferenceError && (
                                    <Form.Text className="text-danger">
                                        {paymentReferenceError}
                                    </Form.Text>
                                )}
                                {paymentType !== 6 && paymentType !== 1 && (
                                    <Button
                                        onClick={validatepayment}
                                        disabled={
                                            isLoading ||
                                            paymentReferenceValid !== null
                                        }
                                    >
                                        {isLoading ? (
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                            />
                                        ) : (
                                            "Validate Payment"
                                        )}
                                    </Button>
                                )}
                                {paymentReferenceValid && (
                                    <Form.Text
                                        className={
                                            paymentReferenceValid.includes(
                                                "balance",
                                            )
                                                ? "text-success"
                                                : "text-danger"
                                        }
                                    >
                                        {paymentReferenceValid}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </>
                    )}

                    {(showPaymentReference || step === 2) && (
                        <Form.Group controlId="comment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                required // This makes the comment field required
                            />
                            {!comment && (
                                <Form.Text className="text-danger">
                                    Comment is required.
                                </Form.Text>
                            )}{" "}
                            {/* Show error if comment is empty */}
                        </Form.Group>
                    )}

                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitDisabled}
                        className={isSubmitDisabled ? "fade-disabled" : ""}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default VarianceClearanceModal;
