import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const DownloadButton = ({ existingFunction, reportType }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Set current year as default
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // State to control loading

  // Get current date to prevent selecting future dates
  const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Month is 0-based, so add 1

  // Function to handle the download logic
  const handleDownload = async () => {
    setLoading(true); // Start the loader when downloading
    if (reportType === 'daily' && selectedDate) {
      await existingFunction(selectedDate); // For daily report, pass the full date
    } else if (reportType === 'monthly' && selectedMonth && selectedYear) {
      await existingFunction(selectedMonth, selectedYear); // For monthly report, pass the month and year
    }
    setLoading(false); // Stop the loader after download completes
    setShowModal(false); // Close modal after submitting the date/month/year
  };

  // Functions to handle modal show/hide
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {reportType === 'daily' ? 'Download Daily Report' : 'Download Monthly Report'}
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select {reportType === 'daily' ? 'Date' : 'Month and Year'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {reportType === 'daily' ? (
              // Daily report input (single date)
              <Form.Group>
                <Form.Label>Select a Date:</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  max={today} // Prevent future dates
                />
                {selectedDate > today && (
                  <Form.Text className="text-danger">
                    Date cannot be in the future.
                  </Form.Text>
                )}
              </Form.Group>
            ) : (
              // Monthly report input (month and year)
              <>
                <Form.Group>
                  <Form.Label>Select Month:</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Select Year:</Form.Label>
                  <Form.Control
                    type="number"
                    value={selectedYear}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value <= currentYear) { // Validate future year
                        setSelectedYear(value);
                      }
                    }}
                    placeholder="2024"
                    min="2000"
                    max={currentYear} // Prevent selecting future years
                  />
                </Form.Group>
                {selectedYear === currentYear && selectedMonth > currentMonth && (
                  <Form.Text className="text-danger">
                    Month cannot be in the future.
                  </Form.Text>
                )}
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleDownload}
            disabled={
              loading ||
              (reportType === 'daily' && (!selectedDate || selectedDate > today)) ||
              (reportType === 'monthly' &&
                (!selectedMonth || !selectedYear || (selectedYear === currentYear && selectedMonth > currentMonth)))
            }
          >
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Downloading...
              </>
            ) : (
              'Download'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadButton;
