import React, { useState, useContext, useEffect } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';  // Import react-select
import TransactionFormModal from './addMpesaCode';
import MpesaActionModal from './toggleMpesaCodeStatus';

import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import CustomersContext from '../../context/otogas/customers/customersContext';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import AuthContext from '../../context/auth/authContext';
import StationsContext from '../../context/otogas/stations/stationsContext';

const ListMpesaSales = () => {
    const customersContext = useContext(CustomersContext);
    const { mpesa_payments, getMpesaPayments, notification, clear_notification } = customersContext;
    const stationsContext = useContext(StationsContext);
    const { tills, listTills } = stationsContext; // Fetch tills
    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const [showAddCodeModal, setShowAddCodeModal] = useState(false);
    const [selectedTill, setSelectedTill] = useState(null); // State for selected till
    const [showAlert, setShowAlert] = useState(false); // Define showAlert state
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    // Effect to fetch tills and set a default till on component mount
    useEffect(() => {
        listTills(); // Fetch tills on component mount
    }, []);

    useEffect(() => {
        if (tills.length > 0 && !selectedTill) {
            setSelectedTill({
                value: tills[0].tillNumber,
                label: tills[0].tillName || tills[0].tillNumber,
            });
        }
    }, [tills, selectedTill]);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true); // Show alert when notification is received
        }
    }, [notification]);

    // Effect to filter mpesa_payments based on selected till
    useEffect(() => {
        if (selectedTill) {
            console.log(selectedTill)
            getMpesaPayments(selectedTill.tillNumber)
        }
    }, [selectedTill]);

    const handleAddTransaction = () => {
        setShowAddCodeModal(true);
    };

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    const handleCloseModals = () => {
        setShowAddCodeModal(false);
    };

    // Map tills for the react-select dropdown
    const tillOptions = tills.map(till => ({
        value: till.tillNumber,
        label: till.tillName || till.tillNumber
    }));

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <TransactionFormModal
                    show={showAddCodeModal}
                    handleClose={handleCloseModals}
                />

                <SweetAlertWrapper
                    show={showAlert} // Use showAlert state for SweetAlertWrapper
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <h2>Mpesa Payments</h2>

                <Row className="mb-3">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Mpesa Transactions</Card.Title>
                                <Card.Text>
                                    Manage Mpesa transactions and codes.
                                </Card.Text>
                                <Button variant="primary" className="mb-2" onClick={handleAddTransaction}>
                                    Add Mpesa Transaction
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6}>
                        <Select
                            options={tillOptions}
                            placeholder="Select a Till"
                            value={selectedTill}
                            onChange={setSelectedTill}
                        />
                    </Col>
                </Row>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mpesa_payments.map((payment, index) => (
                            <tr key={index}>
                                <td>{payment.transID}</td>
                                <td>{payment.amount}</td>
                                <td>{payment.customer}</td>
                                <td>{payment.date}</td>
                                <td>
                                    <Button variant="info" size="sm" onClick={() => { }}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </Main>
        </>
    );
};

export default ListMpesaSales;
