import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomersContext from "../../context/otogas/customers/customersContext";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import AddSaleModal from "../sales/addSale";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import AuthContext from "../../context/auth/authContext";
import { formatDate } from "date-fns";
import userCan from "../../config/user_can";

const Shiftvariances = () => {
    const stationsContext = useContext(StationsContext);
    const customersContext = useContext(CustomersContext);
    const authContext = useContext(AuthContext);
    const { shift_variances, listShiftvariances } = stationsContext;
    const { clearVariance, notification, clear_notification } =
        customersContext;
    const { user } = authContext;

    const [showDeferModal, setShowDeferModal] = useState(false);
    const [showWriteOffModal, setShowWriteOffModal] = useState(false);
    const [showAddSaleModal, setShowAddSaleModal] = useState(false);
    const [selectedVariance, setSelectedVariance] = useState(null);
    const [deferReason, setDeferReason] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    const [searchTerm, setSearchTerm] = useState("");
    const [filterStation, setFilterStation] = useState("");
    const [filterNozzle, setFilterNozzle] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        listShiftvariances();
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const filteredVariances = shift_variances
        .filter(
            (variance) =>
                variance.stationName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                variance.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                variance.shiftNumber.includes(searchTerm),
        )
        .filter((variance) => {
            if (!filterStation && !filterNozzle) return true;
            return (
                (filterStation
                    ? variance.stationName === filterStation
                    : true) &&
                (filterNozzle ? variance.nozzleName === filterNozzle : true)
            );
        });

    const handleDefer = (shiftNumber) => {
        setSelectedVariance(shiftNumber);
        setShowDeferModal(true);
    };

    const handleWriteOff = (shiftNumber) => {
        setSelectedVariance(shiftNumber);
        setShowWriteOffModal(true);
    };

    const handleConfirmDefer = async () => {
        if (deferReason) {
            await clearVariance("defer", selectedVariance, deferReason);
            setShowDeferModal(false);
            setDeferReason("");
        } else {
            alert("Please provide a reason to defer.");
        }
    };

    const handleConfirmWriteOff = async () => {
        await clearVariance("write_off", selectedVariance);
        setShowWriteOffModal(false);
    };

    const handleShowAddSaleModal = (variance) => {
        setSelectedVariance(variance);
        setShowAddSaleModal(true);
    };

    // const handleCloseAddSaleModal = () => {
    //   setShowAddSaleModal(false);
    //   setSelectedVariance(null);
    // };

    const handleClose = () => {
        setShowDeferModal(false);
        setShowWriteOffModal(false);
        setShowAddSaleModal(false);
    };

    const handleConfirm = () => {
        if (notification && notification.type === "success") {
            handleClose();
            // reset()
        }

        setShowAlert(false);
        clear_notification();
    };

    // Pagination calculation
    const totalItems = filteredVariances.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentItems = filteredVariances.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const page = "Shift Variances";

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <h2>{page}</h2>

                {/* Search and Filters */}
                <div className="row mb-4">
                    <div className="col-md-4">
                        <Form.Control
                            type="text"
                            placeholder="Search by station, shift number or attendant"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Select
                            value={filterStation}
                            onChange={(e) => setFilterStation(e.target.value)}
                        >
                            <option value="">All Stations</option>
                            {[
                                ...new Set(
                                    shift_variances.map((v) => v.stationName),
                                ),
                            ].map((station) => (
                                <option key={station} value={station}>
                                    {station}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="col-md-4">
                        <Form.Select
                            value={filterNozzle}
                            onChange={(e) => setFilterNozzle(e.target.value)}
                        >
                            <option value="">All Nozzles</option>
                            {[
                                ...new Set(
                                    shift_variances.map((v) => v.nozzleName),
                                ),
                            ].map((nozzle) => (
                                <option key={nozzle} value={nozzle}>
                                    {nozzle}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Shift</th>
                            <th>Station</th>
                            <th>Dispenser</th>
                            <th>Nozzle</th>
                            <th>Attendant</th>
                            <th>Date</th>
                            <th>Opening</th>
                            <th>Closing</th>
                            <th>Expected Closing</th>
                            <th>Sold Qty</th>
                            <th>Variance</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length === 0 ? (
                            <tr>
                                <td colSpan="12" className="text-center">
                                    No shift variances found
                                </td>
                            </tr>
                        ) : (
                            currentItems.map((shiftvariance) => (
                                <tr key={shiftvariance.id}>
                                    <td>{shiftvariance.shiftNumber}</td>
                                    <td>{shiftvariance.stationName}</td>
                                    <td>{shiftvariance.dispenserName}</td>
                                    <td>{shiftvariance.nozzleName}</td>
                                    <td>{shiftvariance.name}</td>
                                    <td>
                                        {formatDate(
                                            new Date(shiftvariance.dateCreated),
                                            "yyyy-MM-dd",
                                        )}
                                    </td>
                                    <td>{shiftvariance.openingReading}</td>
                                    <td>{shiftvariance.closingReading}</td>
                                    <td>
                                        {shiftvariance.expectedClosingReading}
                                    </td>
                                    <td>{shiftvariance.quantitySold}</td>
                                    <td>{shiftvariance.variance}</td>
                                    <td>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-secondary dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Actions
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            handleDefer(
                                                                shiftvariance.shiftNumber,
                                                            )
                                                        }
                                                        disabled={
                                                            !userCan(
                                                                user,
                                                                "POST:Sales:DeferVariance",
                                                            )
                                                        }
                                                    >
                                                        Defer Variance
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            handleWriteOff(
                                                                shiftvariance.shiftNumber,
                                                            )
                                                        }
                                                        disabled={
                                                            !userCan(
                                                                user,
                                                                "POST:Sales:WriteOffVariance",
                                                            )
                                                        }
                                                    >
                                                        Write Off Variance
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            handleShowAddSaleModal(
                                                                shiftvariance,
                                                            )
                                                        }
                                                    >
                                                        Add Sale
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                />

                {/* Defer Modal */}
                <Modal
                    show={showDeferModal}
                    onHide={() => setShowDeferModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Defer Variance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Reason for Deferring</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reason"
                                value={deferReason}
                                onChange={(e) => setDeferReason(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowDeferModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmDefer}>
                            Confirm Defer
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Write Off Modal */}
                <Modal
                    show={showWriteOffModal}
                    onHide={() => setShowWriteOffModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Write Off Variance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Are you sure you want to write off the variance for
                            Shift Number {selectedVariance}?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowWriteOffModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleConfirmWriteOff}
                        >
                            Confirm Write Off
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Add Sale Modal */}
                <AddSaleModal
                    show={showAddSaleModal}
                    handleClose={handleClose}
                    selectedVariance={selectedVariance}
                />
            </Main>
        </>
    );
};

export default Shiftvariances;
