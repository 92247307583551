import React, { useState, useContext, useEffect } from "react";
import {
    Dropdown,
    Table,
    Form,
    Button,
    Row,
    Col,
    Modal,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Layout/Pagination";
import Select from "react-select";

import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import RemoveSale from "./removeSale";
import TransferSale from "./transferSale";
import CustomersContext from "../../context/otogas/customers/customersContext";
import StationsContext from "../../context/otogas/stations/stationsContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import AuthContext from "../../context/auth/authContext";
import DownloadButton from "../../components/util/downloader";
import {
    download_sales_per_day,
    download_monthly_sales,
} from "../../api/otogas";

import userCan from "../../config/user_can";

const ListSales = () => {
    const customersContext = useContext(CustomersContext);
    const {
        sales,
        salesTotalRecords,
        listSales,
        payment_types,
        listPaymentTypes,
        notification,
        clear_notification,
    } = customersContext;
    const stationsContext = useContext(StationsContext);
    const {
        dispensers,
        nozzles,
        listDispensers,
        listNozzles,
        stations,
        listStations,
    } = stationsContext;
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15); // Number of items per page
    const [filters, setFilters] = useState({
        shiftNumber: "",
        stationCode: "",
        dispenserName: "",
        nozzleName: "",
        paymentTypeName: "",
        startDate: "",
        endDate: "",
    });

    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [showRemoveSaleModal, setShowRemoveSaleModal] = useState(false);
    const [showTransferSaleModal, setShowTransferSaleModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null); // Store entire sale object
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    useEffect(() => {
        listDispensers();
        listNozzles();
        listStations();
    }, []);

    console.log(filters);

    useEffect(() => {
        listSales(
            filters.shiftNumber,
            filters.dispenserName,
            filters.nozzleName,
            filters.paymentTypeName,
            filters.startDate,
            filters.endDate,
            currentPage,
            itemsPerPage,
        );
        listPaymentTypes();
    }, [currentPage, filters, itemsPerPage]);

    const handleFilterChange = (selectedOption, actionMeta) => {
        setFilters({
            ...filters,
            [actionMeta.name]: selectedOption ? selectedOption.value : "",
        });
    };

    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({
            ...filters,
            startDate: start,
            endDate: end,
        });
    };

    const handleApplyFilters = () => {
        setCurrentPage(1); // Reset to first page
        listSales(
            filters.shiftNumber,
            filters.stationCode,
            filters.dispenserName,
            filters.nozzleName,
            filters.paymentTypeName,
            filters.startDate,
            filters.endDate,
            1,
            itemsPerPage,
        );
    };

    const handleRemoveSale = (sale) => {
        setSelectedSale(sale);
        setShowRemoveSaleModal(true);
    };

    const handleTransferSale = (sale) => {
        setSelectedSale(sale); // Store the entire sale object
        setShowTransferSaleModal(true);
    };

    const handleCloseModals = () => {
        setShowRemoveSaleModal(false);
        setShowTransferSaleModal(false);
        setSelectedSale(null);
    };

    const totalPages = Math.ceil(salesTotalRecords / itemsPerPage);

    const stationOptions = stations.map((station) => ({
        value: station.stationCode,
        label: station.stationName,
    }));
    const dispenserOptions = dispensers
        .filter((d) => d.stationCode === filters.stationCode)
        .map((dispenser) => ({
            value: dispenser.dispenserCode,
            label: dispenser.dispenserName,
        }));
    const nozzleOptions = nozzles
        .filter((n) => n.dispenserCode === filters.dispenserName)
        .map((nozzle) => ({
            value: nozzle.nozzleCode,
            label: nozzle.nozzleName,
        }));

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={() => {
                        setShowAlert(false);
                        clear_notification();
                    }}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <h2>List of Sales</h2>

                {/* <Form>
                    <Row className="mb-3 align-items-center">
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Shift Number"
                                name="shiftNumber"
                                value={filters.shiftNumber}
                                onChange={e => setFilters({ ...filters, shiftNumber: e.target.value })}
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                placeholder="Select Station"
                                name="stationCode"
                                options={stationOptions}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                placeholder="Select Dispenser"
                                name="dispenserName"
                                options={dispenserOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.stationCode}
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                placeholder="Select Nozzle"
                                name="nozzleName"
                                options={nozzleOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.dispenserName}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                as="select"
                                name="paymentTypeName"
                                value={filters.paymentTypeName}
                                onChange={e => setFilters({ ...filters, paymentTypeName: e.target.value })}
                            >
                                <option value="">All Payment Types</option>
                                {(app_payment_types || []).map(type => (
                                    <option key={type.paymentTypeId} value={type.paymentTypeName}>
                                        {type.paymentTypeName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Button variant="primary" onClick={() => setShowDateRangeModal(true)} block>
                                Select Date Range
                            </Button>
                        </Col>
                        <Col md={6}>
                            <DownloadButton existingFunction={download_sales_per_day} reportType="daily" />
                        </Col>
                        <Col md={6}>
                            <DownloadButton existingFunction={download_monthly_sales} reportType="monthly" />
                        </Col>
                    </Row>
                </Form> */}

                <Form>
                    <Row className="mb-3 align-items-center">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                placeholder="Shift Number"
                                name="shiftNumber"
                                value={filters.shiftNumber}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        shiftNumber: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Station"
                                name="stationCode"
                                options={stationOptions}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Dispenser"
                                name="dispenserName"
                                options={dispenserOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.stationCode}
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Nozzle"
                                name="nozzleName"
                                options={nozzleOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.dispenserName}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Control
                                as="select"
                                name="paymentTypeName"
                                value={filters.paymentTypeName}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        paymentTypeName: e.target.value,
                                    })
                                }
                            >
                                <option value="">All Payment Types</option>
                                {(payment_types || []).map((type) => (
                                    <option
                                        key={type.paymentTypeId}
                                        value={type.paymentTypeName}
                                    >
                                        {type.paymentTypeName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col md={2}>
                            <Button
                                variant="secondary"
                                onClick={() => setShowDateRangeModal(true)}
                                block
                            >
                                Select Date Range
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mb-3 align-items-center">
                        <Col md={2}>
                            <DownloadButton
                                existingFunction={download_sales_per_day}
                                reportType="daily"
                            />
                        </Col>
                        <Col md={2}>
                            <DownloadButton
                                className="btn btn-secondary"
                                existingFunction={download_monthly_sales}
                                reportType="monthly"
                            />
                        </Col>
                    </Row>
                </Form>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Shift Number</th>
                            <th>Station</th>
                            <th>Quantity</th>
                            <th>Vehicle Registration</th>
                            <th>Dispenser</th>
                            <th>Nozzle</th>
                            <th>Amount</th>
                            <th>Payment Type</th>
                            <th>Sale Id</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {/* <tbody>
                        {sales.map((sale, index) => (
                            <tr key={sale.id}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{sale.shiftNumber}</td>
                                <td>{sale.stationName}</td>
                                <td>{sale.quantity}</td>
                                <td>{sale.vehicleRegistrationNumber}</td>
                                <td>{sale.dispenserName}</td>
                                <td>{sale.nozzleName}</td>
                                <td>{sale.amount}</td>
                                <td>{sale.paymentTypeName}</td>
                                <td>{sale.saleId}</td>
                                <td>{new Date(sale.dateCreated).toLocaleString()}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => handleTransferSale(sale)}
                                                disabled={!userCan(user, 'POST:Sales:TransferSaleToAnotherNozzle')}
                                            >
                                                Transfer Sale
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                onClick={() => handleRemoveSale(sale)}
                                                disabled={!userCan(user, 'POST:Sales:ReverseSaleAsync')}
                                            >
                                                Reverse Sale
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                                onClick={() => navigate(`/otogas/payments/${sale.saleId}`)}
                                            >
                                                View Payments
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody> */}
                    <tbody>
                        {sales.length === 0 ? (
                            <tr>
                                <td colSpan="12" className="text-center">
                                    No sales available at the moment
                                </td>
                            </tr>
                        ) : (
                            sales.map((sale, index) => (
                                <tr key={sale.id}>
                                    <td>
                                        {(currentPage - 1) * itemsPerPage +
                                            index +
                                            1}
                                    </td>
                                    <td>{sale.shiftNumber}</td>
                                    <td>{sale.stationName}</td>
                                    <td>{sale.quantity}</td>
                                    <td>{sale.vehicleRegistrationNumber}</td>
                                    <td>{sale.dispenserName}</td>
                                    <td>{sale.nozzleName}</td>
                                    <td>{sale.amount}</td>
                                    <td>{sale.paymentTypeName}</td>
                                    <td>{sale.saleId}</td>
                                    <td>
                                        {new Date(
                                            sale.dateCreated,
                                        ).toLocaleString()}
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant=""
                                                id="dropdown-basic"
                                            >
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        handleTransferSale(sale)
                                                    }
                                                    disabled={
                                                        !userCan(
                                                            user,
                                                            "POST:Sales:TransferSaleToAnotherNozzle",
                                                        )
                                                    }
                                                >
                                                    Transfer Sale
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() =>
                                                        handleRemoveSale(sale)
                                                    }
                                                    disabled={
                                                        !userCan(
                                                            user,
                                                            "POST:Sales:ReverseSaleAsync",
                                                        )
                                                    }
                                                >
                                                    Reverse Sale
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() =>
                                                        navigate(
                                                            `/otogas/payments/${sale.saleId}`,
                                                        )
                                                    }
                                                >
                                                    View Payments
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={salesTotalRecords}
                />
            </Main>

            {/* Remove Sale Modal */}
            <RemoveSale
                show={showRemoveSaleModal}
                handleClose={handleCloseModals}
                saleId={selectedSale?.saleId}
            />

            {/* Transfer Sale Modal */}
            <TransferSale
                show={showTransferSaleModal}
                handleClose={handleCloseModals}
                sale={selectedSale} // Pass the entire sale object
            />

            {/* Date Range Modal */}
            <Modal
                show={showDateRangeModal}
                onHide={() => setShowDateRangeModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        selected={filters.startDate}
                        onChange={handleDateRangeChange}
                        startDate={filters.startDate}
                        endDate={filters.endDate}
                        selectsRange
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Date Range"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDateRangeModal(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowDateRangeModal(false);
                            handleApplyFilters();
                        }}
                    >
                        Apply Date Range
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ListSales;
