import React, {useContext} from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import CustomersContext from '../../context/otogas/customers/customersContext';

const TransactionFormModal = ({ show, handleClose }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const customerContext = useContext(CustomersContext);
    const { addMpesaTransaction } = customerContext;

    const onSubmit = (data) => {
        console.log(data);
        addMpesaTransaction(data);
        // Handle form submission (API call, etc.)
        reset();  // Reset form fields after submission
        handleClose();  // Close modal after form submission
    };


    return (
        <>
            {/* Modal structure */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Transaction Form</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* Transaction ID */}
                        <Form.Group controlId="transID">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Transaction ID"
                                {...register('transID', { required: true })}
                                isInvalid={!!errors.transID}
                            />
                            <Form.Control.Feedback type="invalid">
                                Transaction ID is required.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Amount */}
                        <Form.Group controlId="amount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Amount"
                                {...register('amount', { required: true, min: 1 })}
                                isInvalid={!!errors.amount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.amount?.type === 'required' ? 'Amount is required.' : 'Amount must be greater than 0.'}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Business Short Code */}
                        <Form.Group controlId="businessShortCode">
                            <Form.Label>Business Short Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Business Short Code"
                                {...register('businessShortCode', { required: true })}
                                isInvalid={!!errors.businessShortCode}
                            />
                            <Form.Control.Feedback type="invalid">
                                Business Short Code is required.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Phone Number */}
                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Phone Number"
                                {...register('phoneNumber', {
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Phone number must be 10 digits.'
                                    }
                                })}
                                isInvalid={!!errors.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phoneNumber?.message || 'Phone number is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Submit Button */}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TransactionFormModal;
