import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import CustomerContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import AddCustomerVehicle from "./AddCustomerVehicle"; // Assuming this is the modal for adding vehicle
import Pagination from "../../components/Layout/Pagination";
import userCan from "../../config/user_can";
import { download_customer_balances } from "../../api/otogas";
import UpdateCustomerModal from "./updateCustomer";

import AuthContext from "../../context/auth/authContext";
import { get } from "react-hook-form";

const ListCustomers = () => {
    const customerContext = useContext(CustomerContext);
    const {
        customers,
        customerTotalRecords,
        getCustomers,
        notification,
        clear_notification,
    } = customerContext;

    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const [pageSize, setPageSize] = useState(10); // Initial page size
    const [currentPage, setCurrentPage] = useState(1); // Local page number state
    const [itemsPerPage] = useState(15); // Items per page (based on API structure)
    const [customerName, setCustomerName] = useState(""); // Search state for name
    const [customerPhone, setCustomerPhone] = useState(""); // Search state for phone number
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
    const [showCustomerUpdateModal, setShowCustomerUpdateModal] =
        useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
    // const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Fetch customers when the component mounts and whenever the page or search criteria change
    useEffect(() => {
        getCustomers(currentPage, itemsPerPage, customerName, customerPhone);
    }, [currentPage, getCustomers, itemsPerPage, customerName, customerPhone]);

    // Handle notifications
    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
        getCustomers(currentPage, itemsPerPage, customerName, customerPhone);
    };

    // Apply search on pressing Enter
    const handleSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            setCurrentPage(1); // Reset to first page on new search
            getCustomers(1, itemsPerPage, customerName, customerPhone);
        }
    };

    // const handleAddVehicle = (customerId) => {
    //   setSelectedCustomer(customerId);
    //   setShowAddVehicleModal(true);
    // };

    // const handleCloseAddVehicle = () => {
    //   setShowAddVehicleModal(false);
    //   setSelectedCustomer(null);
    // };

    const handleCloseCustomerUpdateModal = () => {
        setShowCustomerUpdateModal(false);
        setSelectedCustomer(null);
        getCustomers(1, itemsPerPage, customerName, customerPhone);
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            customers.map((customer) => ({
                Name: customer.customerName,
                Email: customer.customerEmail,
                "Phone Number": customer.customerPhone,
                "ID Number": customer.identificationNumber,
                "KRA Pin": customer.kraPin,
                "Date Created": new Date(
                    customer.dateCreated,
                ).toLocaleDateString(),
            })),
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
        const excelData = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        saveAs(
            new Blob([excelData], { type: "application/octet-stream" }),
            "customers.xlsx",
        );
    };

    // Calculate total pages
    const totalPages = Math.ceil(customerTotalRecords / itemsPerPage);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Customer" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                {/* <AddCustomerVehicle show={showAddVehicleModal} handleClose={handleCloseAddVehicle} customerId={selectedCustomer} /> */}
                <UpdateCustomerModal
                    show={showCustomerUpdateModal}
                    handleClose={handleCloseCustomerUpdateModal}
                    customer={selectedCustomer}
                />
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">List Customers</h5>

                        {/* Buttons Row */}
                        <div className="d-flex justify-content-between mb-3">
                            {/* <Link className="btn btn-primary" id="add-user" to="/otogas/customers/add"
                aria-disabled={!userCan(user, 'POST:Customer:AddCustomer')}
              >
                Add Customer
              </Link> */}

                            <Link
                                className={`btn btn-primary ${!userCan(user, "POST:Customer:AddCustomer") ? "disabled" : ""}`}
                                id="add-user"
                                to={
                                    userCan(user, "POST:Customer:AddCustomer")
                                        ? "/otogas/customers/add"
                                        : "#"
                                }
                                tabIndex={
                                    !userCan(user, "POST:Customer:AddCustomer")
                                        ? -1
                                        : 0
                                } // Remove from tab order if disabled
                                aria-disabled={
                                    !userCan(user, "POST:Customer:AddCustomer")
                                }
                            >
                                Add Customer
                            </Link>
                            <div>
                                <button
                                    className="btn btn-success"
                                    onClick={downloadExcel}
                                >
                                    Download Excel
                                </button>
                            </div>
                        </div>

                        {/* Search Form */}
                        <form className="mb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={customerName}
                                        onChange={(e) =>
                                            setCustomerName(e.target.value)
                                        }
                                        onKeyPress={handleSearchKeyPress} // Apply search on Enter
                                        placeholder="Search by Name"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={customerPhone}
                                        onChange={(e) =>
                                            setCustomerPhone(e.target.value)
                                        }
                                        onKeyPress={handleSearchKeyPress} // Apply search on Enter
                                        placeholder="Search by Phone Number"
                                    />
                                </div>
                            </div>
                        </form>

                        {/* Customers Table */}
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">ID Number</th>
                                    <th scope="col">KRA Pin</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.length > 0 ? (
                                    customers.map((customer, index) => (
                                        <tr key={customer.customerCode}>
                                            <th scope="row">
                                                {(currentPage - 1) *
                                                    itemsPerPage +
                                                    index +
                                                    1}
                                            </th>
                                            <td>{customer.customerName}</td>
                                            <td>{customer.customerEmail}</td>
                                            <td>{customer.customerPhone}</td>
                                            <td>
                                                {customer.identificationNumber ||
                                                    "N/A"}
                                            </td>
                                            <td>{customer.kraPin || "N/A"}</td>
                                            <td>
                                                {new Date(
                                                    customer.dateCreated,
                                                ).toLocaleDateString()}
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        className="btn btn-custom-actions dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Actions
                                                    </button>
                                                    {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <Link
                                className="dropdown-item"
                                state={customer}
                                to={`/otogas/customers/${customer.customerCode}`}
                              >
                                View
                              </Link>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#" onClick={() => handleAddVehicle(customer.customerCode)}>
                                Add Vehicle
                              </a>
                            </li>
                          </ul> */}

                                                    <ul
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton"
                                                    >
                                                        <li>
                                                            {/* {userCan(user, 'GET:Customer:ViewCustomer') ? ( */}
                                                            <Link
                                                                className="dropdown-item"
                                                                state={customer}
                                                                to={`/otogas/customers/${customer.customerCode}`}
                                                            >
                                                                View
                                                            </Link>
                                                            {/* ) : (
                                <span className="dropdown-item disabled" aria-disabled="true">
                                  View
                                </span>
                              )} */}
                                                        </li>
                                                        <li>
                                                            {userCan(
                                                                user,
                                                                "POST:Customer:UpdateCustomer",
                                                            ) ? (
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                        setSelectedCustomer(
                                                                            customer,
                                                                        );
                                                                        setShowCustomerUpdateModal(
                                                                            true,
                                                                        );
                                                                    }}
                                                                >
                                                                    Update
                                                                </button>
                                                            ) : (
                                                                <span
                                                                    className="dropdown-item disabled"
                                                                    aria-disabled="true"
                                                                >
                                                                    Update
                                                                    Customer
                                                                </span>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            No Customers found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {/* Pagination */}
                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListCustomers;
